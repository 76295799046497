import React from 'react'
import Img from 'gatsby-image'
import useHomeLinkMode from '../../../hooks/useHomeLinkMode'
import './guideline-card.scss'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'

const GuidelineCard = props => {
   const { homeLink } = useHomeLinkMode()
	const userInfo=dataLayerUserInfo()
	const dataLaterAutoPauta = (link, nameBanner) => {
		let loc=''
		let pathName=''
		let host=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
			pathName= window.location.pathname
			host = window.location.host
		}
		if(pathName.split('/')[0]!=='productos'){
			if(link.split('/')[1]==='#puntos-de-venta'){
				pushDataLayerEvent({
					event: 'pop_up',
					action_type: 'Click',
					label: "PUNTOS DE VENTA CERCA DE TI",
					single_item_id: "",
  					single_item_name: "",
					  link_text: "PUNTOS DE VENTA DONDE COMPRAR NUESTROS",
					  link_url: homeLink + link,
					  link_domain: host.split('www.')[0]
				})
			}
			
		}else {
			pushDataLayerEvent({event: 'Interacciones - Articulos',
			category: 'academia tena',
			action: 'click',
			label: homeLink + link,
			banner_name: nameBanner,
			location: loc, 
			user_data: userInfo.user_data? userInfo.user_data: '',
			log_status: userInfo.log_status,})
		}
	}


	return (
		<>
			{props.guideline && props.guideline.length > 0 ? (
				props.guideline.map((guideline, index) => (
					<div
						key={index}
						className='f-card-product f-card-guideline'
						onClick={e => {
							e.preventDefault()							
							window.location.href = guideline.redireccion.includes("http") ?  homeLink + guideline.redireccion : guideline.redireccion 
							dataLaterAutoPauta(guideline.redireccion, guideline.imagenes[0].title)
						}}
					>
						<Img
							className='f-img-guideline'
							fluid={guideline.imagenes[0].fluid}
							alt={
								guideline.imagenes[0].description
									? guideline.imagenes[0].description
									: 'image'
							}
						/>
					</div>
				))
			) : (
				<div
					className='f-card-product f-card-guideline'
					onClick={e => {
						e.preventDefault()
						window.location.href = props.guideline.redireccion.includes("http") ? props.guideline.redireccion : homeLink + props.guideline.redireccion
						dataLaterAutoPauta(props.guideline.redireccion, props.guideline.imagenes[0].title)
					}}
				>
					<Img
						className='f-img-guideline'
						fluid={props.guideline.imagenes[0].fluid}
						alt={
							props.guideline.imagenes[0].description
								? props.guideline.imagenes[0].description
								: 'image'
						}
					/>
				</div>
			)}
		</>
	)
}

export default GuidelineCard
